/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import styles from '../styles/termsofUse.module.scss';

const TermsAndConditions = () => (
  <Layout>
    <SEO />
    <div className={styles.banner} />
    <div className={styles.container}>
      <div className={`${styles.pageContainer} container`}>
        <h1 className={styles.title}>Cookie Policy</h1>
        <div className={styles.content}>
          <div>
            {/* INTRODUCTION */}
            <h3 className={styles.headingH3}>INTRODUCTION</h3>
            <div className={styles.sectionContent}>
              <p>
                Klub Works Pvt. Ltd. (hereinafter referred to as “We”, “Us”,
                “Klub” or “Our” which expression shall mean and include its
                affiliates, successors, and permitted assigns) may use cookies,
                web beacons, tracking pixels, and other tracking technologies
                when you visit our website https://www.klubworks.com/, including
                any other media form, media channel, mobile website, or mobile
                application related or connected thereto (collectively, the
                “Site”) to help customize the Site and improve your experience
              </p>
              <br />
              <p>
                We reserve the right to make changes to this Cookie Policy at
                any time and for any reason. We will alert you about any changes
                by updating the “Last Updated” date of this Cookie Policy. Any
                changes or modifications will be effective immediately upon
                posting the updated Cookie Policy on the Site, and you waive the
                right to receive specific notice of each such change or
                modification.
              </p>
              <br />
              <p>
                You are encouraged to periodically review this Cookie Policy to
                stay informed of updates. You will be deemed to have been made
                aware of, will be subject to, and will be deemed to have
                accepted the changes in any revised Cookie Policy by your
                continued use of the Site after the date such revised Cookie
                Policy is posted.
              </p>
            </div>

            {/* USE OF COOKIES */}
            <h3 className={styles.headingH3}>USE OF COOKIES</h3>
            <div className={styles.sectionContent}>
              <p>
                A “cookie” is a string of information that assigns you a unique
                identifier that we store on your computer. Your browser then
                provides that unique identifier to use each time you submit a
                query to the Site. We use cookies on the Site to, among other
                things, keep track of services you have used, record
                registration information, record your user preferences, keep you
                logged into the Site, facilitate purchase procedures, and track
                the pages you visit. Cookies help us understand how the Site is
                being used and improve your user experience.
              </p>
            </div>

            {/* TYPES OF COOKIES */}
            <h3 className={styles.headingH3}>TYPES OF COOKIES</h3>
            <div className={styles.sectionContent}>
              <p>
                The following types of cookies may be used when you visit the
                Site:
              </p>
              <br />
              <p>
                <span className={styles.bold}>Advertising Cookies: </span>
                Advertising cookies are placed on your computer by advertisers
                and ad servers in order to display advertisements that are most
                likely to be of interest to you. These cookies allow advertisers
                and ad servers to gather information about your visits to the
                Site and other websites, alternate the ads sent to a specific
                computer, and track how often an ad has been viewed and by whom.
                These cookies are linked to a computer and do not gather any
                personal information about you.
              </p>
              <br />
              <p>
                <span className={styles.bold}>Analytics Cookies: </span>
                Analytics cookies monitor how users reached the Site, and how
                they interact with and move around once on the Site. These
                cookies let us know what features on the Site are working the
                best and what features on the Site can be improved.
              </p>
              <br />
              <p>
                <span className={styles.bold}>Our Cookies: </span>
                Our cookies
                are “first-party cookies”, and can be either permanent or
                temporary. These are necessary cookies, without which the Site
                won&#x27;t work properly or be able to provide certain features
                and functionalities. Some of these may be manually disabled in
                your browser but may affect the functionality of the Site.
              </p>
              <br />
              <p>
                <span className={styles.bold}>Personalization Cookies: </span>
                Personalization cookies are used to recognize repeat visitors to
                the Site. We use these cookies to record your browsing history,
                the pages you have visited, and your settings and preferences
                each time you visit the Site.
              </p>
              <br />
              <p>
                <span className={styles.bold}>Security Cookies: </span>
                Security
                cookies help identify and prevent security risks. We use these
                cookies to authenticate users and protect user data from
                unauthorized parties.
              </p>
              <br />
              <p>
                <span className={styles.bold}>Site Management Cookies: </span>
                Site management cookies are used to maintain your identity or
                session on the Site so that you are not logged off unexpectedly,
                and any information you enter is retained from page to page.
                These cookies cannot be turned off individually, but you can
                disable all cookies in your browser.
              </p>
              <br />
              <p>
                <span className={styles.bold}>Third-Party Cookies: </span>
                Third-party cookies may be placed on your computer when you
                visit the Site by companies that run certain services we offer.
                These cookies allow the third parties to gather and track
                certain information about you. These cookies can be manually
                disabled in your browser.
              </p>
            </div>

            {/* CONTROL OF COOKIES */}
            <h3 className={styles.headingH3}>CONTROL OF COOKIES</h3>
            <div className={styles.sectionContent}>
              <p>
                Most browsers are set to accept cookies by default. However, you
                can remove or reject cookies in your browser’s settings. Please
                be aware that such action could affect the availability and
                functionality of the Site.
              </p>
              <br />
              <p>
                For more information on how to control cookies, check your
                browser or device’s settings for how you can control or reject
                cookies, or visit the following links:
              </p>
              <p>
                <a
                  href="https://support.apple.com/kb/ph19214?locale=en_US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apple Safari
                </a>
              </p>
              <p>
                <a
                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Chrome
                </a>
              </p>
              <p>
                <a
                  href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Microsoft Edge
                </a>
              </p>
              <p>
                <a
                  href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  Microsoft Internet Explorer
                </a>
              </p>
              <p>
                <a
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mozilla Firefox
                </a>
              </p>
              <p>
                <a
                  href="http://www.opera.com/help/tutorials/security/cookies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Opera
                </a>
              </p>
              <p>
                <a
                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Android (Chrome)
                </a>
              </p>
              <p>
                <a
                  href="https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blackberry
                </a>
              </p>
              <p>
                <a
                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Iphone or Ipad (Chrome)
                </a>
              </p>
              <p>
                <a
                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Iphone or Ipad (Safari)
                </a>
              </p>
            </div>

            {/* PRIVACY POLICY */}
            <h3 className={styles.headingH3}>PRIVACY POLICY</h3>
            <div className={styles.sectionContent}>
              <p>
                For more information about how we use information collected by
                cookies and other tracking technologies, please refer to our
                {' '}
                <a href="/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                {' '}
                posted on the Site. This Cookie Policy is part of and is
                incorporated into our Privacy Policy. By using the Site, you
                agree to be bound by this Cookie Policy and our Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default TermsAndConditions;
