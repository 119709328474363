/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, lang, meta, title, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            twitterUsername
            image
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const ogImage = image || site.siteMetadata.image;
  let pageUrl = 'https://klubworks.com';
  if (typeof window !== 'undefined') {
    pageUrl = (window && window.location.href);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title="Klub"
      titleTemplate={`%s - ${metaTitle}`}
      meta={[
        {
          name: `title`,
          content: `Klub - ${metaTitle}`
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords
        },
        {
          name: 'author',
          content: site.siteMetadata.author
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitterUsername
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterUsername
        },
        {
          name: `twitter:title`,
          content: `Klub - ${metaTitle}`
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:url`,
          content: pageUrl
        },
        {
          name: `twitter:image`,
          content: ogImage
        },
        {
          property: `og:title`,
          content: `Klub - ${metaTitle}`
        },
        {
          property: `og:image`,
          content: ogImage
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:url`,
          content: pageUrl
        },
        {
          property: `og:site_name`,
          content: `Klubworks`
        },
        {
          property: `og:type`,
          content: `website`
        }
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
  title: ''
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string
};

export default SEO;
